import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const CheckList = ({ variant, children, className }) => {
    const classes = [
        'c-checklist',
        ...(variant ? [`c-checklist--${variant}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return <ul className={concatenatedClasses}>{children}</ul>
}

CheckList.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark']),
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

CheckList.defaultProps = {
    children: '<li>I am a list item</li>'
}

export default CheckList
